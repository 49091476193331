<template>
    <div class="columns is-centered">
        <div class="column is-four-fifths is-full-touch">
            <p>
                <strong>Politica de privacidad</strong> <br>
                <br>
                Esta política de privacidad describe cómo se recopila, utiliza, comparte y protege
                la información personal de los usuarios en nuestra aplicación disponible en Google
                Play Console. Al utilizar nuestra aplicación, acepta los términos de esta política
                de privacidad. <br>
                <br>
                Recopilación y uso de información <br>
                Recopilamos información personal del usuario solo cuando el usuario nos proporciona
                dicha información voluntariamente. La información personal que podemos recopilar
                incluye su nombre, dirección de correo electrónico y otra información que el usuario
                decida proporcionarnos. También podemos recopilar información no personal, como
                datos sobre el dispositivo y el uso de la aplicación. <br>
                <br>
                Utilizamos la información personal del usuario para proporcionar servicios y mejorar
                la experiencia del usuario en nuestra aplicación. También podemos utilizar la
                información no personal para fines estadísticos y analíticos. <br>
                <br>
                Compartir información <br>
                No compartimos ni vendemos información personal del usuario con terceros sin su
                consentimiento explícito. Podemos compartir información no personal con terceros
                para fines estadísticos y analíticos. <br>
                <br>
                Protección de la información <br>
                Tomamos medidas razonables para proteger la información personal del usuario contra
                el acceso no autorizado o ilegal, la divulgación o pérdida accidental. Sin embargo,
                no podemos garantizar que las medidas de seguridad sean impenetrables. <br>
                Notificación en caso de violación de datos <br>
                En caso de una violación de datos que afecte a los datos personales recopilados por
                nuestra aplicación, notificaremos a los usuarios afectados según lo exige la ley
                aplicable. <br>
                <br>
                Consentimiento del usuario <br>
                Al utilizar nuestra aplicación, el usuario acepta esta política de privacidad y nos
                otorga el consentimiento para recopilar, utilizar y compartir su información
                personal de acuerdo con los términos de esta política. <br>
                <br>
                Derechos del usuario <br>
                El usuario tiene derecho a acceder, corregir o eliminar su información personal en
                cualquier momento. También puede retirar su consentimiento para el uso de su
                información personal en cualquier momento. Para hacerlo, contáctenos a través de la
                información de contacto proporcionada en nuestra aplicación. <br>
                <br>
                Cumplimiento legal <br>
                Cumplimos con todas las leyes y regulaciones aplicables relacionadas con la
                privacidad y protección de datos personales. <br>
            </p>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Privacy',

    computed: {
    },
};
</script>

<style lang="scss">
</style>
